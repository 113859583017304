<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12>
                <v-card>
                    <v-card-title class="title">
                        <v-icon>school</v-icon> &nbsp;
                        Add School Information
                    </v-card-title>
                    <v-card-text>
                        <v-form v-model="company.validation.valid" ref="companyInfoForm" lazy-validation>
                            <v-timeline
                                    class="user_add_form"
                                    align-top
                                    dense>
                                <v-timeline-item color="red" small>
                                    <v-layout pt-3>
                                        <v-flex>
                                            <strong>Services</strong>
                                            <div class="caption">Select services subscription for the company.</div>
                                            <v-flex xs11>
                                                <v-combobox
                                                        hint="Required basic permissions would be added to the services by default."
                                                        multiple
                                                        v-model="selectedServices"
                                                        :items="services"
                                                        :rules="company.validation.role"
                                                        item-text="name"
                                                        chips
                                                        icon="account_circle"
                                                        label="Select services.">
                                                    <template v-slot:selection="service">
                                                        <v-chip
                                                                :selected="service.selected"
                                                                color="primary"
                                                                :key="JSON.stringify(selectedServices.name)"
                                                                class="v-chip--select-multi">
                                                            <v-avatar>
                                                                <v-icon>assignment_ind</v-icon>
                                                            </v-avatar>
                                                            {{ service.item.name}}
                                                        </v-chip>
                                                    </template>
                                                </v-combobox>
                                            </v-flex>
                                        </v-flex>
                                    </v-layout>
                                </v-timeline-item>


                                <v-timeline-item
                                        color="pink lighten-1"
                                        small>
                                    <v-layout wrap pt-3>
                                        <v-flex xs8>
                                            <strong>General Info.</strong>
                                            <div class="caption">Basic company informations.</div>
                                            <v-flex pt-3 xs11>

                                                <v-container style="padding:0" grid-list-md>
                                                    <v-layout row wrap>
                                                        <v-flex xs6>
                                                            <v-text-field :disabled="!selectedServices.length" solo
                                                                          autocomplete="off"
                                                                          label="Company Name*" required class="pa-0"
                                                                          v-model="form.name"
                                                                          name="name"
                                                                          :rules="company.validation.fnameRule"
                                                                          :error-messages="form.errors.get('name')"/>
                                                        </v-flex>

                                                        <v-flex sm2>
                                                            <v-text-field solo autocomplete="off"
                                                                          label="Short Code*"
                                                                          :disabled="!selectedServices.length"
                                                                          required class="pa-0"
                                                                          v-model="form.short_code"
                                                                          name="rank"
                                                                          :error-messages="form.errors.get('short_code')"/>
                                                        </v-flex>

                                                        <v-flex sm4>
                                                            <v-text-field solo autocomplete="off"
                                                                          label="Domain*"
                                                                          :disabled="!selectedServices.length"
                                                                          required class="pa-0"
                                                                          v-model="form.domain"
                                                                          name="rank"
                                                                          :error-messages="form.errors.get('domain')"/>
                                                        </v-flex>
                                                        <v-flex xs12>
                                                            <v-text-field solo autocomplete="off"
                                                                          label="Company Slogan"
                                                                          :disabled="!selectedServices.length"
                                                                          required class="pa-0"
                                                                          v-model="form.slogan"
                                                                          name="rank"
                                                                          :error-messages="form.errors.get('slogan')"/>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-container>
                                            </v-flex>
                                        </v-flex>

                                        <v-flex pt-2 xs3>
                                            <v-card>
                                                <v-card-text>
                                                    <img :src="cropperImg.imgSrc" v-if="cropperImg.imgSrc"
                                                         height="150"/>
                                                    <img :src="'https://image.flaticon.com/icons/svg/138/138570.svg'"
                                                         height="150" v-else/>
                                                    <input
                                                            type="file"
                                                            style="display: none"
                                                            ref="image"
                                                            accept="image/*"
                                                            @change="onFilePicked">
                                                    <v-btn
                                                            v-if="cropperImg.imgSrc"
                                                            fab
                                                            small
                                                            bottom
                                                            right
                                                            absolute
                                                            @click.native.stop="resetImagePicker"
                                                            style="z-index: 1"
                                                            color="red white--text">
                                                        <v-icon dark>close</v-icon>
                                                    </v-btn>
                                                    <v-btn
                                                            v-if="!cropperImg.imgSrc"
                                                            fab
                                                            small
                                                            bottom
                                                            right
                                                            :disabled="!selectedServices.length"
                                                            @click="onPickFile"
                                                            absolute
                                                            style="z-index: 1"
                                                            color="green white--text">
                                                        <v-icon dark>add</v-icon>
                                                    </v-btn>

                                                </v-card-text>
                                            </v-card>
                                        </v-flex>
                                    </v-layout>
                                </v-timeline-item>


                                <v-timeline-item
                                        color="orange lighten-1"
                                        small>
                                    <v-layout wrap pt-3>
                                        <v-flex>
                                            <strong>Contact and Address Info.</strong>
                                            <div class="caption">Company address and contact informations.</div>
                                            <v-flex pt-3 xs11>
                                                <v-container style="padding:0" grid-list-md>
                                                    <v-layout row wrap>
                                                        <v-flex xs3>
                                                            <v-text-field :disabled="!selectedServices.length" solo
                                                                          autocomplete="off"
                                                                          label="Contact Person" required class="pa-0"
                                                                          v-model="form.contact.contact_person"
                                                                          name="person"/>
                                                        </v-flex>

                                                        <v-flex sm3>
                                                            <v-text-field v-mask="'##########'"
                                                                          solo autocomplete="off"
                                                                          label="Mobile"
                                                                          :disabled="!selectedServices.length"
                                                                          required class="pa-0"
                                                                          v-model="form.contact.primary_mobile"
                                                                          name="mobile"/>
                                                        </v-flex>

                                                        <v-flex sm2>
                                                            <v-text-field solo autocomplete="off"
                                                                          label="Phone"
                                                                          v-mask="'##########'"
                                                                          :disabled="!selectedServices.length"
                                                                          required class="pa-0"
                                                                          v-model="form.contact.primary_phone"
                                                                          name="phone"/>
                                                        </v-flex>

                                                        <v-flex sm4>
                                                            <v-text-field solo autocomplete="off"
                                                                          label="Email"
                                                                          :disabled="!selectedServices.length"
                                                                          required class="pa-0"
                                                                          v-model="form.contact.primary_email"
                                                                          name="phone"/>
                                                        </v-flex>
                                                        <v-flex xs3>
                                                            <v-text-field solo autocomplete="off"
                                                                          label="FAX"
                                                                          :disabled="!selectedServices.length"
                                                                          required class="pa-0"
                                                                          v-model="form.contact.fax"
                                                                          name="fax"/>
                                                        </v-flex>

                                                        <v-flex xs12>
                                                            <v-layout>
                                                                <v-flex xs12 sm2>
                                                                    <v-autocomplete
                                                                            solo
                                                                            :disabled="!selectedServices.length"
                                                                            :items="states"
                                                                            v-model="company.addressInfo.data.permanent_state_id"
                                                                            label="Province/State"
                                                                            :rules="company.addressInfo.validation.permanent_state_id_rule"
                                                                            data-vv-name="select"
                                                                            required
                                                                    />
                                                                </v-flex>
                                                                <v-flex xs12 sm3>
                                                                    <v-autocomplete
                                                                            solo
                                                                            :disabled="!company.addressInfo.data.permanent_state_id"
                                                                            :items="permanent_districts"
                                                                            v-model="company.addressInfo.data.permanent_district_id"
                                                                            label="District"
                                                                            :rules="company.addressInfo.validation.permanent_district_id_rule"
                                                                            data-vv-name="select"
                                                                            required
                                                                    />
                                                                </v-flex>

                                                                <v-flex xs12 sm3>
                                                                    <v-autocomplete
                                                                            solo
                                                                            :disabled="!company.addressInfo.data.permanent_district_id"
                                                                            :items="permanent_cities"
                                                                            :rules="company.addressInfo.validation.permanent_city_id_rule"
                                                                            v-model="company.addressInfo.data.permanent_city_id"
                                                                            label="Municipality/City"
                                                                            data-vv-name="select"
                                                                            required
                                                                    />
                                                                </v-flex>
                                                                <v-flex xs12 sm1>
                                                                    <v-autocomplete
                                                                            solo
                                                                            :disabled="!company.addressInfo.data.permanent_city_id"
                                                                            :items="ward_count_permanent"
                                                                            :rules="company.addressInfo.validation.permanent_ward_no"
                                                                            v-model="company.addressInfo.data.permanent_ward_no"
                                                                            label="Ward"
                                                                            data-vv-name="select"
                                                                            required
                                                                    />
                                                                </v-flex>
                                                                <v-flex xs12 sm3 md3>
                                                                    <v-text-field solo
                                                                                  label="Street/Tole"
                                                                                  :disabled="!company.addressInfo.data.permanent_city_id"
                                                                                  v-model="company.addressInfo.data.permanent_address_line"
                                                                                  :rules="company.addressInfo.validation.permanent_address_line_rule"/>
                                                                </v-flex>
                                                            </v-layout>
                                                        </v-flex>

                                                        <v-flex xs12 sm3 md3>
                                                            <v-text-field solo
                                                                          label="Logitude"
                                                                          :disabled="!selectedServices.length"
                                                                          v-model="form.address.lat"/>
                                                        </v-flex>

                                                        <v-flex xs12 sm3 md3>
                                                            <v-text-field solo
                                                                          label="Latitude"
                                                                          :disabled="!selectedServices.length"
                                                                          v-model="form.address.lon"/>
                                                        </v-flex>

                                                    </v-layout>
                                                </v-container>
                                            </v-flex>
                                        </v-flex>
                                    </v-layout>
                                </v-timeline-item>


                                <v-timeline-item
                                        color="orange lighten-1"
                                        small>
                                    <v-layout wrap pt-3>
                                        <v-flex>
                                            <strong>Other Info.</strong>
                                            <div class="caption">Company registration and billing info.</div>
                                            <v-flex pt-3 xs11>
                                                <v-container style="padding:0" grid-list-md>
                                                    <v-layout row wrap>
                                                        <v-flex xs4>
                                                            <v-text-field :disabled="!selectedServices.length" solo
                                                                          autocomplete="off"
                                                                          label="School Registration No." required
                                                                          class="pa-0"
                                                                          v-model="form.other_info.regno"
                                                                          name="person"/>
                                                        </v-flex>

                                                        <!--<v-flex xs12>-->
                                                        <v-flex sm3>
                                                            <!--{{form.billing}}-->
                                                            <v-text-field
                                                                    solo autocomplete="off"
                                                                    label="Bill Generate Name"
                                                                    :disabled="!selectedServices.length"
                                                                    required class="pa-0"
                                                                    v-model="form.billing.name"
                                                                    name="mobile"/>
                                                        </v-flex>
                                                        <v-flex sm3>
                                                            <v-text-field solo autocomplete="off"
                                                                          label="TPIN"
                                                                          :disabled="!selectedServices.length"
                                                                          required class="pa-0"
                                                                          v-model="form.billing.tpin"
                                                                          name="tpin"/>
                                                        </v-flex>

                                                        <v-flex sm8>
                                                            <v-text-field solo autocomplete="off"
                                                                          label="Billing Address"
                                                                          :disabled="!selectedServices.length"
                                                                          required class="pa-0"
                                                                          v-model="form.billing.address"
                                                                          name="phone"/>
                                                        </v-flex>

                                                    </v-layout>
                                                </v-container>
                                            </v-flex>
                                        </v-flex>
                                    </v-layout>
                                </v-timeline-item>


                                <v-timeline-item
                                        color="orange lighten-1"
                                        small>
                                    <v-layout wrap pt-3>
                                        <v-flex>
                                            <strong>Social Info.</strong>
                                            <div class="caption">Facebook, Youtube, Twitter, Instagram.</div>
                                            <v-flex pt-3 xs11>
                                                <v-container style="padding:0" grid-list-md>
                                                    <v-layout row wrap>
                                                        <v-flex xs6>
                                                            <v-text-field :disabled="!selectedServices.length" solo
                                                                          autocomplete="off"
                                                                          label="Facebook" required
                                                                          class="pa-0"
                                                                          v-model="form.social.facebook"
                                                                          name="person"/>
                                                        </v-flex>
                                                        <v-flex xs6>
                                                            <v-text-field :disabled="!selectedServices.length" solo
                                                                          autocomplete="off"
                                                                          label="Youtube" required
                                                                          class="pa-0"
                                                                          v-model="form.social.youtube"
                                                                          name="person"/>
                                                        </v-flex>

                                                        <v-flex xs6>
                                                            <v-text-field :disabled="!selectedServices.length" solo
                                                                          autocomplete="off"
                                                                          label="Twitter" required
                                                                          class="pa-0"
                                                                          v-model="form.social.twitter"
                                                                          name="person"/>
                                                        </v-flex>

                                                        <v-flex xs6>
                                                            <v-text-field :disabled="!selectedServices.length" solo
                                                                          autocomplete="off"
                                                                          label="Instagram" required
                                                                          class="pa-0"
                                                                          v-model="form.social.instagram"
                                                                          name="person"/>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-container>
                                            </v-flex>
                                        </v-flex>
                                    </v-layout>
                                </v-timeline-item>




                                <v-timeline-item
                                        color="green lighten-1"
                                        small>
                                    <v-layout wrap pt-3>
                                        <v-flex>
                                            <strong>About company.</strong>
                                            <div class="caption">Describe in brief about the company.</div>
                                            <v-flex pt-3 xs11>
                                                <v-container style="padding:0" grid-list-md>
                                                    <v-layout row wrap>
                                                        <v-flex xs12>
                                                            <wysiwyg :disabled="!selectedServices.length"
                                                                     v-model="form.description"/>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-container>
                                            </v-flex>
                                        </v-flex>
                                    </v-layout>
                                </v-timeline-item>

                                <v-card-actions class="pl-5">
                                    <!--<v-btn outlined color="warning">Cancel</v-btn>-->
                                    <v-btn outlined color="success" :disabled="!selectedServices.length" @click="store">
                                        Save
                                    </v-btn>

                                    <v-spacer/>
                                </v-card-actions>


                            </v-timeline>
                        </v-form>

                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import Form from '@/library/Form'
    import statesData from '@/assets/json/states.json'
    import districtDataData from '@/assets/json/districts.json'
    import cityData from '@/assets/json/cities.json'
    import {getPropertyFromArrayObject} from '@/library/helpers';

    export default {
        data() {
            return {
                cropperImg: {
                    cropDialog: false,
                    imgSrc: '',
                    cropImg: '',
                    uploadCropped: {
                        url: '',
                        image: '',
                        cropped: '',
                        file: ''
                    },
                    name: null
                },
                form: new Form({
                    name: '',
                    short_code: '',
                    registration_no: '',
                    domain: '',
                    slogan: '',
                    description: '',
                    logo: {},
                    pdistrict_id: '',
                    address: {
                        lon: '',
                        lat: ''
                    },
                    contact: {
                        contact_person: '',
                        primary_mobile: '',
                        primary_phone: '',
                        fax: '',
                        primary_email: ''
                    },
                    social: {
                        facebook: '',
                        instagram: '',
                        youtube: '',
                        twitter: ''
                    },
                    other_info: {
                        regno: '',
                        representative: ''
                    },
                    billing: {
                        name: '',
                        tpin: '',
                        address: '',
                    },
                    detail: '',
                    merchant: '',
                    service_id: '',
                    pcity_id: '',
                    pstreet: '',
                    pstate_id: '',
                    pward_no: ''
                }, '/api/company'),
                company: {
                    validation: {
                        valid: false,
                        name: [
                            (v) => !!v || 'Is required'
                        ]
                    },
                    addressInfo: {
                        data: {
                            permanent_state_id: '',
                            permanent_city_id: '',
                            permanent_district_id: '',
                            permanent_address_line: '',
                            permanent_ward_no: ''
                        },
                        validation: {
                            valid: false,
                            permanent_state_id_rule: [
                                (v) => !!v || 'Please select state!'
                            ],
                            permanent_ward_no: [
                                (v) => !!v || 'Ward no required!'
                            ],
                            role: [
                                (v) => !!v || 'You must specify a role!'
                            ],
                            permanent_address_line_rule: [
                                (v) => !!v || 'Address is required!'
                            ],
                            permanent_district_id_rule: [
                                (v) => !!v || 'Please select district!'
                            ],
                            permanent_city_id_rule: [
                                (v) => !!v || 'Please select municipality/city!'
                            ]
                        }
                    },
                },
                permanent_districts: [],
                permanent_cities: [],
                ward_count_permanent: [],
                selectedServices: [],
                services: [],
                states: [],
                pagination: {
                    rowsPerPage: 50
                },
                statesData,
                districtDataData,
                cityData,
                updateState: false
            }
        },
        watch: {
            'form.name': {
                handler(name) {
                    this.form.name = this.capitalizeString(name)
                }
            },
            'form.slogan': {
                handler(name) {
                    this.form.slogan = this.capitalizeString(name)
                }
            },
            'form.short_code': {
                handler(name) {
                    if (name)
                        this.form.short_code = name.toUpperCase();
                }
            },
            'form.contact.person': {
                handler(name) {
                    this.form.contact.person = this.capitalizeString(name);
                }
            },
            'company.addressInfo.data.permanent_address_line': {
                handler(name) {
                    this.company.addressInfo.data.permanent_address_line = this.capitalizeString(name)
                }
            },
            'company.addressInfo.data.permanent_state_id': {
                handler(v) {
                    this.getDistricts('permanent', v)
                }
            },
            'company.addressInfo.data.permanent_district_id': {
                handler(v) {
                    this.getCities('permanent', v);
                }
            },
            'company.addressInfo.data.permanent_city_id': {
                handler(v) {
                    this.getWardCount('permament');
                }
            }
        },
        mounted() {
            this.getServices();
            this.getStates();
            if (this.$route.params.id) {
                this.updateState = true;
                this.get();
            } else {
                this.updateState = false;
            }
        },
        methods: {
            queryString() {
                let json = this.pagination;
                return '?' + Object.keys(json).map(key => {
					if(typeof json[key] == 'object') {
						let vals = [...json[key]];
                        if(vals.length) return encodeURIComponent(key) + '=' + encodeURIComponent(vals.join(','));
                        else return null;
					}
					return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
				}).join('&');
            },
            get(params) {
                let query = [null, undefined].includes(params) ? this.queryString() : params;
                this.$rest.get('/api/company/' + this.$route.params.id).then((res) => {
                    this.company.addressInfo.data.permanent_state_id = parseInt(res.data.address.state_id);
                    this.company.addressInfo.data.permanent_city_id = parseInt(res.data.address.city_id);
                    this.company.addressInfo.data.permanent_district_id = parseInt(res.data.address.district_id);
                    this.company.addressInfo.data.permanent_address_line = res.data.address.address;
                    this.company.addressInfo.data.permanent_ward_no = parseInt(res.data.address.ward_no);
                    this.cropperImg.imgSrc = res.data.logo;


                    //details
                    if (res.data.detail && Object.keys(res.data.detail).length > 0) {
                        this.form.other_info.regno = res.data.detail.regno;
                        this.form.billing.name = res.data.detail.billing.name;
                        this.form.billing.tpin = res.data.detail.billing.tpin;
                        this.form.billing.address = res.data.detail.billing.address;
                    }
                    // this.form.pward_no = parseInt(this.company.addressInfo.data.permanent_ward_no);
                    this.form.edit(res.data);

                    this.selectedServices = res.data.services;
                });
            },
            getServices() {
                this.$rest.get('/api/service').then(({data}) => {
                    this.services = data.data;
                });
            },
            store() {
                if (this.$refs.companyInfoForm.validate()) {
                    this.form.service_id = getPropertyFromArrayObject('id', this.selectedServices).join(',');
                    this.form.pcity_id = this.company.addressInfo.data.permanent_city_id;
                    this.form.pstate_id = this.company.addressInfo.data.permanent_state_id;
                    this.form.pdistrict_id = this.company.addressInfo.data.permanent_district_id;
                    this.form.pstreet = this.company.addressInfo.data.permanent_address_line;
                    this.form.pward_no = this.company.addressInfo.data.permanent_ward_no;

                    this.form.logo = this.cropperImg.file;

                    this.form.store().then(() => {
                        this.$router.push({name: 'schools'});
                    });
                }
            },

            getStates(type) {
                this.states = this.statesData.map(item => {
                    return {text: item.name, value: item.id}
                });
            },
            getDistricts(type, id) {
                this.permanent_districts = this.districtDataData.filter(item => item.state_id === this.company.addressInfo.data.permanent_state_id).map(item => {
                    return {value: item.id, text: item.name}
                });
                this.permanent_cities = [];
            },

            getCities(type, id) {
                this.permanent_cities = this.cityData.filter(item => item.district_id === this.company.addressInfo.data.permanent_district_id).map(item => {
                    return {value: item.id, text: item.name}
                });
            },

            getWardCount(type) {
                let $this = this;
                let totalWards = [];
                if (type === 'permament') {
                    let city = this.cityData.filter(function (city, key) {
                        return $this.company.addressInfo.data.permanent_city_id === city.id;
                    });
                    this.ward_count_permanent = [];
                    // this.staff.addressInfo.data.permanent_ward_no = '';
                    if (city.length) {
                        city = city[0];
                        let totalNumberOfWards = parseInt(city.ward_count);
                        for (let i = 1; i <= totalNumberOfWards; i++) {
                            totalWards.push(i);
                        }
                    }
                    this.ward_count_permanent = totalWards;
                }
            },
            capitalizeString(string) {
                if (string === undefined || string === null) return '';
                return string.charAt(0).toUpperCase() + string.slice(1)
            },

            /**
             * Image Uploader
             */
            onPickFile() {
                this.$refs.image.click()
            },
            onFilePicked(e) {
                const files = e.target.files;
                let fileName = files[0].name;
                if (fileName.lastIndexOf('.') <= 0) {
                    return
                }

                if (typeof FileReader === 'function') {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(files[0]);
                    let $this = this;
                    fileReader.addEventListener('load', () => {
                        $this.cropperImg.url = fileReader.result;
                        $this.cropperImg.name = fileName;
                        $this.cropperImg.imgSrc = event.target.result;
                        $this.cropperImg.file = files[0];

                        // const imageCompressor = new ImageCompressor();
                        // imageCompressor.compress(files[0], {quality: .5})
                        //     .then((result) => {
                        //         this.imagePicker.image = result
                        //     })
                        //     .catch((err) => {
                        //         // Handle the error
                        //     })
                    })

                    // reader.onload = (event) => {
                    //     this.cropperImg.name = file.name;
                    // };
                    // reader.readAsDataURL(file);
                } else {
                    alert('Sorry, FileReader API not supported');
                }
                this.cropperImg.cropDialog = true;
            },

            resetImagePicker() {
                this.cropperImg = {
                    imgSrc: undefined,
                    name: undefined,
                    file: undefined
                };
                this.$refs.image.value = "";
                this.cropperImg.cropImg = '';
                this.cropperImg.name = '';
            },
        }
    }
</script>

<style lang="scss">
    .user_add_form {
        .v-chip, .v-chip .v-chip__content {
            border-radius: 2px;
            color: white;
        }
    }
</style>